<template>
  <div class="tools-wrap">
    <icon-button
      v-if="openScreenshotModal"
      class="tools-wrap-item"
      white
      @click="$emit('open')"
    >
      <v-icon
        class="white-icon"
      >
        open_in_new
      </v-icon>
    </icon-button>
    <icon-button
      v-if="canDelete"
      class="tools-wrap-item"
      error
      @click="$emit('delete')"
    >
      <v-icon
        class="white-icon"
      >
        delete
      </v-icon>
    </icon-button>
  </div>
</template>

<script>
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'ScreenshotTools',
  components: { IconButton },
  props: {
    openScreenshotModal: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userRole() {
      return this.$store.getters.getSelectedWorkspace.role;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .tools-wrap {
    opacity: 1;
    display: flex;

    &-item {
      margin-right: $spacing-3;

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
