<template>
  <dot-loader
    v-if="getFiltersLoading"
    style="top: 50%"
    :blue="true"
  />
  <section-wrap
    v-else
    title="Screenshot Activity"
  >
    <section-container
      divider
    >
      <template #header>
        <div style="min-height: 164px">
          <filters-bar
            @handle-params="setParams"
          />
          <activity-bar
            :activity-percent="getTotalActivity"
            :activity-time="getDailyTotal"
            :can-delete-time="checkboxValues.length > 0"
            :add-time="getData.permissions && getData.permissions.can_add_time"
            add-time-btn-text="Add manually time"
            @delete-time="callConfirmModal(checkboxValues)"
            @add-manually-time="isOpenAddTimeDialog = true"
          />
        </div>
      </template>
      <template #body>
        <div style="position: relative; min-height: 64px">
          <dot-loader
            v-if="getDataLoading"
            style="top: 0"
            :blue="true"
          />
          <div
            v-else-if="!getDataLoading && (
              getData.activities &&
              Object.keys(getData.activities).length > 0
            )"
            class="screenshot-wrap"
          >
            <screenshot-list
              v-for="(item) in getData.activities"
              :key="item.timeslot"
              v-model="checkboxValues"
              :label="item.title"
              :total-time="item.total_worked"
              :items="item.time_slots"
              :images="getData.screen_origins"
              :timeslot="item.timeslot"
              :permissions="getData.permissions"
              @delete="callConfirmModal"
            />
          </div>
          <empty-no-data v-else>
            There was no activity during this period
          </empty-no-data>
        </div>
      </template>
    </section-container>
    <confirm-modal
      v-if="isOpen"
      v-model="isOpen"
      @save="deleteTimeSlot"
    >
      {{
        dataForDeleteTime.type === 'hour' ?
          'Are you sure you want to delete time?' :
          'Are you sure you want to delete those 10 mins?'
      }}
    </confirm-modal>
    <custom-dialog
      v-if="isOpenAddTimeDialog"
      v-model="isOpenAddTimeDialog"
      title="Adding time"
      btn-text="Add Time"
      @save="submitForm"
    >
      <dot-loader
        v-if="manualTimeList.length === 0"
        style="position: relative"
        :blue="true"
      />
      <validation-observer
        v-else
        ref="observer"
      >
        <v-form>
          <div class="d-flex flex-column">
            <CustomSelect
              v-model="manuallyTimeInfo.project_id"
              :outside="true"
              :items="manuallyTimeProject"
              hide-details="auto"
              no-data-text="No available projects"
              item-text="name"
              item-value="id"
              arrow-class="black-icon"
              project
              label="Project"
              class="mb-4"
            />
            <div class="d-flex flex-column mb-2">
              <span class="times-label">Times</span>
              <div
                v-for="(timeRow, idx) in manualTimeList"
                :key="idx"
                class="d-grid"
              >
                <v-checkbox
                  v-for="item in timeRow"
                  :key="item.time"
                  v-model="manuallyTimeInfo.times"
                  :disabled="item.is_disabled"
                  :value="item.time"
                  hide-details
                  class="mt-0"
                >
                  <template #label>
                    <div
                      class="custom-label"
                      :class="{'custom-label__error': isRequiredCheckboxes}"
                    >
                      <span>{{ item.time }}</span>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="d-flex align-center">
              <div class="explanation-cell">
                <div class="explanation-cell__color explanation-cell__color--white" />
                <div class="explanation-cell__text">
                  Available
                </div>
              </div>
              <div class="explanation-cell">
                <div class="explanation-cell__color explanation-cell__color--accent" />
                <div class="explanation-cell__text">
                  Selected
                </div>
              </div>
              <div class="explanation-cell">
                <div class="explanation-cell__color explanation-cell__color--gray" />
                <div class="explanation-cell__text">
                  Disabled
                </div>
              </div>
            </div>
            <div class="error-wrap">
              <transition name="error">
                <div
                  v-if="isRequiredCheckboxes"
                  class="v-messages theme--light error--text pl-3 pr-3"
                >
                  Required
                </div>
              </transition>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="textarea"
              rules="required|max:100"
            >
              <text-area
                id="reason"
                v-model="manuallyTimeInfo.reason"
                :rows="3"
                :error-messages="errors"
                :counter="100"
                :max-length="100"
                label-text="Reason"
                placeholder="Describe a reason"
              />
            </validation-provider>
          </div>
        </v-form>
      </validation-observer>
    </custom-dialog>
  </section-wrap>
</template>

<script>
import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';
import ActivityBar from '@/components/collections/ActivityBar/ActivityBar';
import ScreenshotList from '@/components/collections/Screenshot/ScreenshotList';
import FiltersBar from '@/components/collections/FiltersBar/FiltersBar';
import store from '@/store';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ConfirmModal from '@/components/collections/Dialog/ConfirmModal';
import CustomDialog from '@/components/collections/Dialog/CustomDialog';
import CustomSelect from '@/components/ui/CustomSelect/CustomSelect';
import TextArea from '@/components/ui/Inputs/TextArea';
import DotLoader from '@/components/ui/Loaders/DotLoader';
import EmptyNoData from '@/components/ui/Empty/EmptyNoData';
import { apiCall } from '@/services/axios';

export default {
  beforeRouteEnter: (to, from, next) => {
    if (!store.state.auth.isAuthorized) {
      next({
        name: 'Login',
      });
    }

    next();
  },
  name: 'Activity',
  components: {
    ConfirmModal,
    CustomDialog,
    CustomSelect,
    FiltersBar,
    ScreenshotList,
    ActivityBar,
    SectionContainer,
    SectionWrap,
    TextArea,
    DotLoader,
    EmptyNoData,
  },

  data() {
    return {
      isOpen: false,
      isOpenAddTimeDialog: false,
      initError: false,
      showAllActivities: false,
      oneTimeActive: true,
      params: {
        filter_start: '',
        filter_end: '',
        project_id: null,
        workspace_id: null,
        member_card_id: null,
      },
      dataForDeleteTime: {
        times: [],
        type: '',
      },
      manuallyTimeInfo: {
        project_id: null,
        day: null,
        reason: '',
        times: [],
      },
      checkUpdates: null,
      manuallyTimeProject: [],
      manualTimeList: [],
      checkboxValues: [],
    };
  },

  computed: {
    ...mapGetters([
      'getFiltersLoading',
      'getData',
      'getDataLoading',
      'getFiltersData',
      'getSelectedProject',
      'getActiveMember',
      'getUserInfo',
      'getDailyTotal',
      'getTotalActivity',
      'getSelectedWorkspace',
      'getTimerStatus',
    ]),
    isRequiredCheckboxes() {
      if (this.initError) {
        return this.manuallyTimeInfo.times.length === 0;
      }

      return false;
    },
  },

  watch: {
    getTimerStatus(isStart) {
      if (!isStart) {
        this.fetchActivitiesData();
      }
    },
    params() {
      this.checkboxValues = [];
    },
    isOpenAddTimeDialog(value) {
      if (value) {
        this.getManualTime();
        this.chooseDefaultProject(this.manuallyTimeProject);
      } else {
        this.initError = false;
        this.manuallyTimeInfo = {
          project_id: null,
          day: null,
          reason: '',
          times: [],
        };
      }
    },
    getSelectedWorkspace: {
      handler: 'fetchActivitiesFilters',
      immediate: true,
    },
  },

  mounted() {
    const CheckUserInterval = 60 * 10;
    const secondsSinceLastTimerTrigger = (Math.floor(new Date().getTime() / 1000)
      % CheckUserInterval);
    const secondsUntilNextTimerTrigger = CheckUserInterval - secondsSinceLastTimerTrigger;
    setTimeout(() => {
      this.checkUpdates = setInterval(this.fetchActivitiesData, (CheckUserInterval + 60) * 1000);
      setTimeout(this.fetchActivitiesData, 60 * 1000);
    }, secondsUntilNextTimerTrigger * 1000);
  },

  methods: {
    ...mapMutations(['updateData', 'updateSelectedProject']),
    ...mapActions(['GET_ACTIVITY', 'DELETE_ACTIVITY']),

    fetchActivitiesFilters(workspace) {
      this.$store.dispatch('GET_ACTIVITIES_FILTERS', workspace?.id);
    },

    fetchActivitiesData() {
      this.$store.dispatch('GET_ALL_ACTIVITY', this.params);
    },

    setParams(fields) {
      const formattedDate = this.$moment(fields.date).format('YYYY-MM-DD');
      this.manuallyTimeProject = [this.getFiltersData.projects
        .find((el) => el.id === fields.project)];

      this.params = {
        filter_start: `${formattedDate} 00:00:00`,
        filter_end: `${formattedDate} 23:59:59`,
        project_id: fields.project,
        workspace_id: this.getSelectedWorkspace.id,
        member_card_id: fields.member,
      };
      this.fetchActivitiesData(this.params);
    },

    async getManualTime() {
      this.manuallyTimeInfo.day = this.params.filter_start
        && this.params.filter_start.split(' ')[0];

      try {
        const { data } = await apiCall('GET',
          'activities/timer/manual', {}, {}, {
            day: `${this.manuallyTimeInfo.day || '2024-01-31'}`,
            member_card_id: this.params.member_card_id,
          });
        this.manualTimeList = data;
      } catch (error) {
        console.log(error);
      }
    },
    chooseDefaultProject(projects) {
      this.manuallyTimeInfo.project_id = projects.length && projects
        .find((el) => el.id).id;
    },
    callConfirmModal(data) {
      this.dataForDeleteTime.type = data.find((el) => el.type) ? 'slot' : 'hour';
      this.dataForDeleteTime.times = data
        .map((el) => (el.type ? { ...el } : { type: 'hour', time: el }));

      this.isOpen = true;
    },
    deleteTimeSlot() {
      this.DELETE_ACTIVITY(
        {
          delete: this.dataForDeleteTime.times,
          ...this.params,
        },
      ).then(() => {
        this.isOpen = false;
        this.$store.commit('addNewSnackBar', {
          text: 'Time is succesfuly deleted',
          status: 'success',
          show: true,
        });
        this.checkboxValues = [];
        this.dataForDeleteTime = {
          times: [],
          type: '',
        };
      });
    },
    submitForm() {
      const observerRef = this.$refs.observer;
      this.initError = true;
      observerRef.validate().then((isValid) => {
        if (isValid && !this.isRequiredCheckboxes) {
          this.manuallyTimeInfo.member_card_id = this.params.member_card_id;
          apiCall('POST', 'activities/timer/manual', {
            ...this.manuallyTimeInfo,
          }).then((response) => {
            this.fetchActivitiesData();
            this.$store.commit('SET_TIMER_ACTIVITY', response.data);
            this.isOpenAddTimeDialog = false;
            this.$store.commit('addNewSnackBar', {
              text: 'Time is successfully added!',
              status: 'success',
              show: true,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

::v-deep.d-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  .v-input--is-dirty {
    .custom-label {
      background: $primary;
      border: 1px solid transparent;

      span {
        color: $white;
      }
    }
  }

  .v-input--is-disabled {
    .custom-label {
      background: $grey-light;
      border: 1px solid transparent;

      span {
        color: $black-darkest;
      }
    }
  }

  .custom-label {
    border: 1px solid $black-darkest;
    border-radius: 6px;
    padding: 5px;

    span {
      color: $black-darkest;
      font-size: 16px;
    }

    &.custom-label__error {
      border: 2px solid #eb5757;
    }
  }

  .v-input--selection-controls__input {
    display: none;
  }
}

.error-wrap {
  min-height: 18px;
  margin-bottom: 10px;
  overflow: hidden;
}

.explanation-cell {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  &__color {
    border: 1px solid $black-darkest;
    width: 18px;
    height: 18px;

    &--accent {
      border: 1px solid transparent;
      background: $primary;
    }
    &--gray {
      border: 1px solid transparent;
      background: $grey-light;
    }
  }
}

.error-leave-active, .error-enter-active {
  transition: .2s ease-in-out;
}
.error-enter, .error-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.times-label {
  display: block;
  color: #656375;
  font-size: 12px;
  margin-bottom: 4px;
}
</style>
