<template>
  <div
    class="screenshot-item"
    :class="{'no-activity': !activityStatus}"
  >
    <div>
      <div
        v-if="activityStatus"
        class="screenshot-title"
      >
        <v-icon
          x-small
          class="dot-icon mr-2"
          :style="{
            'color': getTrackedProject.colors.length
              && getTrackedProject.colors[0]
          }"
        >
          fiber_manual_record
        </v-icon>
        <span class="screenshot-projects">
          {{ getTrackedProject.name }}
        </span>
      </div>
      <div
        class="screenshot-activity"
      >
        <span class="screenshot-percent">
          {{ activityPercent }}%
        </span>
        of {{ getActivityMinutes }} min
      </div>
      <div
        class="screenshot-progress"
      >
        <progress-bar
          :percent="activityPercent"
        />
      </div>
      <div class="screenshot-img">
        <div
          v-if="img"
          class="img-wrap"
        >
          <img
            :src="img"
          >
          <div class="img-tools">
            <screenshot-tools
              :can-delete="permissions && permissions.can_delete_time"
              @open="screenshotPreview = true"
              @delete="$emit('delete', activityId)"
            />
          </div>
        </div>
        <div
          v-else-if="!img && activityStatus"
          class="img-wrap"
        >
          <div class="img-no-img">
            {{ getSourceType }}
          </div>
          <div
            v-if="permissions && permissions.can_delete_time"
            class="img-tools"
          >
            <screenshot-tools
              :open-screenshot-modal="
                source !== 'web' &&
                  source !== 'manual' && img !== ''
              "
              @delete="$emit('delete', activityId)"
            />
          </div>
        </div>
        <img
          v-else
          src="@/static/no-activity.png"
          alt="No activity"
        >
      </div>
      <div class="screenshot-period">
        <span>
          {{ activityPeriod }}
        </span>
      </div>
    </div>
    <screenshot-modal
      v-model="screenshotPreview"
      :items="items"
      :current="currentItem"
    />
  </div>
</template>

<script>
import ProgressBar from '@/components/ui/ProgressBar/ProgressBar';
import ScreenshotTools from '@/components/collections/Screenshot/ScreenshotTools';
import ScreenshotModal from '@/components/collections/Screenshot/ScreenshotModal';

export default {
  name: 'ScreenshotItem',
  components: { ScreenshotModal, ScreenshotTools, ProgressBar },
  props: {
    permissions: {
      type: Object,
      default: (() => {}),
    },
    projects: {
      type: Array,
      default: (() => []),
    },
    activityPercent: {
      type: String,
      default: null,
    },
    activityMinutes: {
      type: String,
      default: null,
    },
    activityPeriod: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: null,
    },
    activityStatus: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: undefined,
    },
    currentItem: {
      type: Number,
      default: 0,
    },
    activityId: {
      type: Number,
      default: null,
    },
    source: {
      type: String,
      default: 'string',
    },
  },
  data() {
    return {
      screenshotPreview: false,
    };
  },
  computed: {
    getActivityMinutes() {
      return this.$moment.utc(this.$moment.duration(this.activityMinutes, 'seconds')
        .asMilliseconds()).format('m');
    },
    getTrackedProject() {
      const initialObj = {
        colors: [],
        name: '',
      };

      if (this.projects && this.projects.length > 0) {
        [...this.projects].forEach((project, idx, arr) => {
          initialObj.colors.push(project.color);
          initialObj.name += (idx === arr.length - 1) ? project.name : `${project.name}, `;
        });
      }

      return initialObj;
    },
    getSourceType() {
      let sourceType = 'No Image';

      if (this.source === 'web') {
        sourceType = 'Web tracker';
      } else if (this.source === 'manual') {
        sourceType = 'Manual Time';
      }

      return sourceType;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.screenshot {
  &-item {
    width: calc(20% - 68px);
    max-width: 240px;
    min-height: 250px;
    max-height: 251px;
    border-radius: $radius-3;
    background-color: $white;
    border: 1px solid $grey-light;
    padding: $spacing-3 0;

    &.no-activity {

      .screenshot-activity {
        margin-top: 25px;
      }

      .screenshot-img {
        img {
          min-height: 135px;
        }
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 $spacing-4;
    margin-bottom: 4px;
    font-size: $type-s;

    span {
      white-space: nowrap;
    }
  }

  &-projects {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-activity {
    padding: 0 $spacing-4;
    font-size: $type-s;
  }

  &-percent {
    color: $success;
    font-size: $type-s;
  }

  &-progress {
    margin: $spacing-1 0 $spacing-2;
    padding: 0 $spacing-4;
  }

  &-period {
    padding: $spacing-1 $spacing-4;
    font-size: $type-s;
  }

  &-img {
    max-width: 238px;
    max-height: 135px;
    min-height: 134px;

    img {
      width: 100%;
    }
  }
}

.img {
  &-wrap {
    position: relative;
    height: 135px;

    img {
      height: 100%;
    }

    &:hover {
      .img-tools {
        opacity: 1;
        transition: opacity 0.3s linear;
      }
    }
  }

  &-tools {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    max-height: 134px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-no-img {
    width: 100%;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-lightest;
    color: $grey;
    font-size: $type-xs;
    font-weight: $font-weight-regular;
  }
}

.v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

@media screen and (min-width: 1350px) and (max-width: 1919px) {
  .screenshot {
    &-item {
      width: 15%;
      min-height: 200px;
      max-height: unset;
      padding-bottom: 0;

      &.no-activity {

      }

      ::v-deep .v-input__control {

        .v-messages {
          display: none !important;
        }
      }
    }
  }
}
</style>
