<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container>
      <icon-button
        class="dialog-close"
        @click="close"
      >
        <v-icon>
          close
        </v-icon>
      </icon-button>
      <div class="slider-pagination">
        {{ currentSlide + 1 }} / {{ items.length }}
      </div>
      <div class="slick-wrap">
        <icon-button
          ref="prevArrow"
          type="button"
          class="slick-custom-prev"
          @click="prev"
        >
          <v-icon>
            keyboard_backspace
          </v-icon>
        </icon-button>
        <icon-button
          ref="nextArrow"
          type="button"
          class="slick-custom-next"
          @click="next"
        >
          <v-icon>
            keyboard_backspace
          </v-icon>
        </icon-button>
        <VueSlickCarousel
          ref="slick"
          v-bind="settings"
          :initial-slide="current"
          @init="initSlick"
          @afterChange="afterChange"
        >
          <div
            v-for="(item, idx) of items"
            :key="idx"
            class="img-wrap"
          >
            <img :src="item.screenshot" alt="">
          </div>
        </VueSlickCarousel>
      </div>
    </v-container>
    <v-app-bar absolute bottom>
      <div>
        {{ screenshotDetails.title }} - Screenshot 1 of 1
      </div>
    </v-app-bar>
  </v-dialog>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'ScreenshotModal',
  title: 'Fraction pagination',
  components: {
    IconButton,
    VueSlickCarousel,
  },
  props: {
    value: Boolean,
    items: {
      type: Array,
      default: undefined,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: this.value,
      settings: {
        lazyLoad: 'ondemand',
        arrows: false,
        dots: false,
        prevArrow: this.$refs.prevArrow,
        nextArrow: this.$refs.nextArrow,
      },
      currentSlide: null,
    };
  },
  computed: {
    screenshotDetails() {
      const item = this.items[this.currentSlide];

      return {
        activity: item?.activity,
        title: item?.title,
      };
    },
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    close() {
      this.isOpen = !this.isOpen;
    },
    afterChange(event) {
      this.currentSlide = event;
    },
    initSlick() {
      this.currentSlide = this.current;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep .v-dialog {
    background-color: black;
    overflow: hidden;
    position: relative;
  }

  .dialog-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .img-wrap {
    max-width: 1210px;
    max-height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }

  .slider-pagination {
    color: $grey-light;
    position: absolute;
    left: 15px;
    top: 15px;
  }
  .slick-wrap {
    position: relative;
    .slick-custom-prev {
      position: absolute;
      left: -80px;
      top: 50%;
    }
    .slick-custom-next {
      position: absolute;
      right: -80px;
      top: 50%;
      transform: rotate(180deg);
    }
  }
  ::v-deep .v-toolbar__content {
    display: flex;
    justify-content: center;
  }
</style>
