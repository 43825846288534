<template>
  <div class="screenshot-list">
    <div class="screenshot-total">
      <v-checkbox
        v-model="model"
        class="screenshot-total-item"
        :class="{'hide': permissions && !permissions.can_delete_time}"
        :disabled="permissions && !permissions.can_delete_time"
        :value="timeslot"
        :label="label"
      />
      <div
        class="screenshot-total-item"
      >
        Total worked: {{ getTotalTime }}
      </div>
    </div>
    <div class="screenshot-list-wrap">
      <screenshot-item
        v-for="(item, index) of items"
        :key="index"
        class="screenshot-list-item"
        :projects="item.projects"
        :activity-minutes="`${item.activity_duration || 600}`"
        :activity-percent="`${item.activity_tracked || 0}`"
        :img="item.screenshot.thumb"
        :activity-status="item.activity_duration !== null"
        :activity-period="item.title"
        :items="images"
        :current-item="getIndexOfImage(item.id)"
        :activity-id="item.id"
        :source="item.source"
        :permissions="permissions"
        @delete="$emit('delete', [{type: 'slot', time: item.time_slot}])"
      />
    </div>
  </div>
</template>

<script>
//  Вместо Current item передать index, который найти по в масиве всех картинок по id
import ScreenshotItem from '@/components/collections/Screenshot/ScreenshotItem';

export default {
  name: 'ScreenshotList',
  components: { ScreenshotItem },
  props: {
    value: {
      type: Array,
      default: (() => []),
    },
    permissions: {
      type: Object,
      default: (() => {}),
    },
    timeslot: {
      type: String,
      default: '',
    },
    items: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    totalTime: {
      type: Number,
      default: 0,
    },
    images: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    getTotalTime() {
      return this.secondsToHms(this.totalTime);
    },
    model: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    secondsToHms(dig) {
      const d = Number(dig);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor(d % 3600 % 60);

      const hours = h > 9 ? h : `0${h}`;
      const minutes = m > 9 ? m : `0${m}`;
      const seconds = s > 9 ? s : `0${s}`;

      return `${hours}:${minutes}:${seconds}`;
    },
    getIndexOfImage(id) {
      return this.images.findIndex((image) => image.id === id);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .screenshot {
    &-list-wrap {
      display: flex;
      flex-wrap: wrap;

    }
    &-list-item {
      margin-bottom: 50px;
      margin-right: 20px;
      max-width: 238px;
      &:last-child {
        margin-right: 0;
      }
    }

    &-total {
      margin-bottom: $spacing-12;
      display: flex;
      align-items: center;

      &-item {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .hide {
    ::v-deep .v-input--selection-controls__input {
      display: none;
    }
  }
</style>
