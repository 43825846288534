<template>
  <div>
    <div class="d-flex align-center mb-2 justify-space-between">
      <input-label
        v-if="labelText"
        class="textarea-label"
        :for="id"
      >
        {{ labelText }}
      </input-label>
    </div>
    <v-textarea
      :id="id"
      class="custom-v__textarea"
      no-resize
      outlined
      hide-details="auto"
      :maxlength="maxLength"
      :counter="counter"
      :rows="rows"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:counter="{ props: {max, value}}">
        <div
          class="textarea-counter"
          :class="[`counter-position-${counterPosition}`]"
        >
          {{ value }}/{{ max }}
        </div>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import InputLabel from '@/components/ui/InputLabel/InputLabel';

export default {
  name: 'TextArea',
  components: {
    InputLabel,
  },
  props: {
    rows: {
      type: [Number, String],
      default: 1,
    },
    placeholder: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: null,
    },
    counter: {
      type: Number,
      default: null,
    },
    counterPosition: {
      type: String,
      default: 'top', // or bottom
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";

::v-deep.custom-v__textarea {
  textarea {
    margin: 0 !important;
    padding: 14px 0 !important;
    &::placeholder {
      color: $grey;
      font-size: $type-base;
    }
  }
  .v-input__slot {
    padding: 0 13px 0 16px !important;
  }
  .v-text-field__details {
    margin-bottom: 0;
  }
}

.textarea-label {
  color: $grey;
  margin-bottom: 0;
}

.textarea-counter {
  color: $grey;
  font-size: $type-xs;
  margin-bottom: 8px;
}

.counter-position-top {
  bottom: 100%;
  position: absolute;
  right: 0;
}
.counter-position-bottom {
  position: relative;
}
</style>
