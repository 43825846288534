<template>
  <v-progress-linear
    :color="colorStatus"
    :value="percent"
  />
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percent: {
      type: String,
      default: null,
    },
  },
  computed: {
    colorStatus() {
      const { percent } = this;

      if (percent >= 80) {
        return 'success';
      }

      if (percent < 79 && percent > 30) {
        return 'warning';
      }

      if (percent <= 30) {
        return '#EB5757';
      }

      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep.v-progress-linear {
    height: 5px !important;
    border-radius: 3px;
    .v-progress-linear__background {
      background-color: $grey-light !important;
    }
    .v-progress-linear__determinate.success {
      color: $success;
    }
  }
</style>
